import { ButtonComponent, IconComponent, TextComponent } from '@aaa-mobile/shared-components'
import { Component } from '@angular/core'
import { IonCol, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRow } from '@ionic/angular/standalone'
import { select, Store } from '@ngrx/store'
import { openPromptDialog } from '../../../../modules/ui/ui.actions'
import { PromptDialogTypes } from '../../../../modules/ui/ui.types'
import { AAAStore } from '../../../../store/root-reducer'
import { selectEligibility, selectIsRapUser } from '../../../../modules/auth/auth.selectors'
import { Eligibility } from '../../../../modules/auth/eligibility.types'
import { selectMemberData } from '../../../../modules/member/member.selectors'
import { MemberInfo } from '../../../../modules/member/member.types'
import { Observable } from 'rxjs'
import { PlusIndicatorValues } from '../../../types'
import { getMemberGreetings } from '../../../utils/entitlement.utils'
import { AsyncPipe, TitleCasePipe } from '@angular/common'
import { ConfigService } from '../../../../modules/config/config.service'
import { AppVersion } from '../../../utils/app-version'
import { LogoComponent } from '../../../layouts/header-redesign/logo/logo.component'

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    IonMenu,
    IonHeader,
    IonContent,
    TextComponent,
    IonList,
    IonItem,
    IonLabel,
    ButtonComponent,
    IconComponent,
    IonRow,
    IonCol,
    IonMenuToggle,
    AsyncPipe,
    TitleCasePipe,
    LogoComponent
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  memberData$: Observable<MemberInfo> = this.store$.pipe(select(selectMemberData));
  eligibility$: Observable<Eligibility> = this.store$.pipe(select(selectEligibility));
  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser));

  appVersion: string
  appEnv: string | boolean = this.configService.getConfig().description
  displayEnv = Boolean(this.configService.getConfig().description)
  displayVersion: boolean = this.configService.getConfig().displayVersion

  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService,
    private appVersionObj: AppVersion,
  ) {
    this.appVersion = !this.configService.getConfig().nativeAppView ? this.appVersionObj.get() : null
  }

  getPlusIndicatorName(plusIndicator: string) {
    return PlusIndicatorValues[plusIndicator] || plusIndicator
  }

  _getMemberGreetings(joinYearDate) {
    return getMemberGreetings(joinYearDate)
  }

  openLanguageSwitcher() {
    this.store$.dispatch(openPromptDialog({
      payload: {
        type: PromptDialogTypes.I18N_SWITCHER
      }
    }))
  }
}
