<app-menu />
<ng-container *ngIf="localeSwitcherEnabled || nativeAppView">
  <app-header-redesign [bgContrast]="true">
    @if (localeSwitcherEnabled) {
      <app-menu-button slot="start" />
    }
    @if (nativeAppView) {
      <app-closable-header slot="end" />
    }
  </app-header-redesign>
</ng-container>
<ion-grid>
  <ion-row>
    <ion-col>
      <app-logo size="large"></app-logo>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="!(isSecure$ | async)">
    <ion-row>
      <ion-col>
        <aaa-text color="dark" align="center" weight="bold"><span style="font-size: 20px;" i18n>Welcome!</span></aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <aaa-text color="dark" align="center" size="footnote">
          @if (isWhiteLabel) {
            @if (isOdometerConfirmation$ | async) {
              <ng-container i18n>Please enter or confirm your vehicle's odometer below to get started with your roadside request.</ng-container>
            } @else {
              <ng-container i18n>Please enter your details below to get started with your roadside request.</ng-container>
            }
          } @else {
            <ng-container i18n>Please enter your AAA Member details to get started with your roadside request.</ng-container>
          }
        </aaa-text>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row class="last-row">
    <ion-col>
      @if (isWhiteLabel) {
        <app-white-label-credentials-v2 [mode]="appId">
        </app-white-label-credentials-v2>
      } @else {
        <app-member-credentials [displayCaptcha]="(isCaptchaRequired$ | async)">
        </app-member-credentials>
      }
    </ion-col>
  </ion-row>
</ion-grid>
