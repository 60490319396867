<ion-card class="ion-no-margin">
  <ion-list class="ion-no-padding">
    @for (callStatus of (callStatusMessageHistory$ | async); track callStatus) {
      <app-request-details-item color="valid"
                                [caption]="callStatus.description"
                                [title]="callStatus.title"
                                [icon]="getIcon(callStatus.callStatus)">
      </app-request-details-item>
    }
  </ion-list>
</ion-card>
