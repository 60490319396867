<app-menu />
<app-header-redesign [bgContrast]="true">
  <app-menu-button slot="start" />
  <app-title>
    <div class="title">
      <aaa-icon size="small" name="circle-filled" color="valid" />
      <ng-container i18n>Request in Progress</ng-container>
    </div>
  </app-title>
  <app-logo size="xsmall" slot="end" />
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-no-padding ion-no-margin">
    @if (!(displayTrackingLink$ | async)) {
      @if (messageStatus$ | async; as messageStatus) {
        <ion-row class="ion-margin">
          <ion-col>
            <aaa-text i18n class="margin-bottom-8" color="text" size="title3" weight="bold">{{ messageStatus.title }}</aaa-text>
            <aaa-text i18n color="text" size="subheadline">{{ messageStatus.description }}</aaa-text>
          </ion-col>
        </ion-row>
      }
    }
    <ion-row>
      <ion-col [ngClass]="(displayTrackingLink$ | async) ? 'tracking-link-container' : 'map-container'">
        <app-progress-map></app-progress-map>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-battery-quotes></app-battery-quotes>
      </ion-col>
    </ion-row>
    @if (!(displayTrackingLink$ | async) && (isStatusUpdateSupported$ | async)) {
      <ion-row class="ion-margin">
        <ion-col>
          <aaa-text class="margin-bottom-8" size="headline" color="text" weight="medium" i18n>Request Status</aaa-text>
          <app-call-status-history></app-call-status-history>
        </ion-col>
      </ion-row>
    }
    <ion-row class="ion-margin">
      <ion-col>
        <aaa-text class="margin-bottom-8" size="headline" color="text" weight="medium" i18n>Your Request Summary</aaa-text>
        <app-request-details [clickable]="false"></app-request-details>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin">
      <ion-col>
        <app-cancel-request></app-cancel-request>
      </ion-col>
    </ion-row>
    @if (!(displayTrackingLink$ | async) && (isStatusUpdateSupported$ | async)) {
      <ion-row class="ion-margin">
        <ion-col>
          <aaa-text class="eta-disclaimer" size="caption" color="medium" i18n>*ETA is only an estimate. A real time ETA will be provided once your service provider is in route.</aaa-text>
        </ion-col>
      </ion-row>
    }
    @if ((advisories$ | async).length) {
      <ion-row class="ion-margin">
        <ion-col>
          @for (advisory of (advisories$ | async); track advisory.msg) {
            <aaa-text class="advisory-message" size="caption" color="medium">{{advisory.msg}}</aaa-text>
          }
        </ion-col>
      </ion-row>
    }
  </ion-grid>
</ion-content>
