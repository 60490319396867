import { Component, input } from '@angular/core'
import { PaceSetterDetails } from '../../../modules/issue/issue.types'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { selectActivePaceSetterDetails, } from '../../../modules/issue/issue.selectors'
import {
  selectBreakdownLocationAddress,
  selectHighwayExit,
  selectLandMarkLocationName,
  selectSpecialAssistance,
} from '../../../modules/location/location.selectors'
import { Option, PromptDialogTypes, StepTypes, TowSections } from '../../../modules/ui/ui.types'
import {
  selectPassengersOption,
  selectTowToSimpleLocation
} from '../../../modules/location/tow-location/tow-location.selectors'
import { SimpleLocation } from '../../../modules/location/location.types'
import { selectMemberActiveVehicle } from '../../../modules/member/member.selectors'
import { constructDetailsSummary } from '../../../modules/issue/issue.utils'
import { map, withLatestFrom } from 'rxjs/operators'
import { selectIsRapUser, selectIsVehicleChangeAllowed } from '../../../modules/auth/auth.selectors'
import { openPromptDialog } from '../../../modules/ui/ui.actions'
import { EditService } from '../../../shared/services/edit.service';
import { formatVehicle } from '../../../shared/pipes/vehicle.pipe';
import { selectActiveCallStatus } from '../../dashboard/calls-statuses/call-status.selectors';
import { AAACallStatus } from '../../dashboard/calls.types';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent {

  clickable = input(true);

  constructor(
    public editService: EditService,
    private store$: Store<AAAStore>
  ) {
  }

  activeCall$: Observable<AAACallStatus> = this.store$.pipe(
    select(selectActiveCallStatus)
  )
  specialAssistance$: Observable<string> = this.store$.pipe(
    select(selectSpecialAssistance)
  )
  activeSituationName$: Observable<any> = this.store$.pipe(
    select(selectActivePaceSetterDetails),
    withLatestFrom(
      this.store$.pipe(select(selectIsRapUser)),
    ),
    map(([details, isRapUser]: [PaceSetterDetails, boolean]) => constructDetailsSummary(details, isRapUser))
  )
  activeVehicle$: Observable<any> = this.store$.pipe(
    select(selectMemberActiveVehicle),
    map((vehicle) => formatVehicle(vehicle))
  )
  isVehicleChangeAllowed$: Observable<boolean> = this.store$.pipe(
    select(selectIsVehicleChangeAllowed)
  )
  selectBreakdownLocationAddress$: Observable<string> = this.store$.pipe(
    select(selectBreakdownLocationAddress)
  )
  selectBreakdownLocationLandmark$: Observable<string> = this.store$.pipe(
    select(selectLandMarkLocationName)
  )
  towLocation$: Observable<SimpleLocation> = this.store$.pipe(
    select(selectTowToSimpleLocation)
  )
  highWayExit$ = this.store$.pipe(select(selectHighwayExit))

  passengersQty$: Observable<string> = this.store$.pipe(
    select(selectPassengersOption),
    map((passengers: Option): string => passengers?.label)
  )

  goToPassengers() {
    this.editService.doEdit(StepTypes.TOWING, TowSections.PASSENGERS)
  }

  handleSpecialAssistanceEdit() {
    this.store$.dispatch(
      openPromptDialog({
        payload: {
          type: PromptDialogTypes.SPECIAL_ASSISTANCE_DIALOG,
        },
      })
    )
  }
}


