import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  ENSURE_LOCATION_SERVICES,
  LOCATION_NEXT,
  LOCATION_TYPE,
  LocationMeta,
  locationNext,
  LocationPayload,
  notifySetBreakdownLocationFailure,
  resetHighwayExits,
  SET_BREAKDOWN_LOCATION,
  SET_HIGHWAY_EXITS,
  SET_LOCATION_CLUB,
  setBreakdownLocationRequest,
  setBreakdownLocationSuccess,
  setHighwayExitsFailure,
  setHighwayExitsRequest,
  setHighwayExitsSuccess,
  setIsHighway,
  setLandMark,
  setLocationClubError,
  setLocationClubRequest,
  setLocationClubSuccess,
  setLocationServicesAvailable,
  setLocationType,
  setShowAdjustLocation,
} from './location.actions'
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { GoogleGeocodeService } from './google-geocode/google-geocode.service'
import { from, Observable, of } from 'rxjs'
import { FSA, PayloadedAction } from '../../shared/types'
import { dispatchErrorAction, googleLocationToAAALocation, } from '../../shared/utils'
import { Action, select, Store } from '@ngrx/store'
import { AAAStore } from '../../store/root-reducer'
import { MemberInfo } from '../member/member.types'
import { ErrorReportingService } from '../../shared/services/error-reporting.service'
import { resetTowDestination } from './tow-location/tow-location.actions'
import { resetAar } from './aar/aar.actions'
import { GoogleCoordinates } from './google-geocode/types'
import { selectUserDefaultCoords } from '../ui/ui.selectors'
import { AAALocation, GoogleLocation, HighwayExits, LocationClubResponse, } from './location.types'
import { hasHighwayNameAndGeometricCenter, isAddressComplete, isHighwayAddressPattern, } from './location.utils'
import { LocationService } from './location.service'
import {
  selectBreakdownLocation,
  selectHasGPSAccess,
  selectIsBreakdownLocationValid,
  selectIsHighway
} from './location.selectors'
import { setServicingClubConfigsRequest } from '../servicing-club/servicing-club.actions'
import { selectActiveCallStatus } from '../dashboard/calls-statuses/call-status.selectors'
import { guessLocationFromCoordsResults } from './google-geocode/google-geocode.utils'
import { ConfigService } from '../config/config.service'
import { advisoriesRequest } from '../advisories/advisories.actions'
import { AAACallStatus } from '../dashboard/calls.types'
import { setVehicleStep } from '../vehicle/vehicle.actions'
import { addPartialCallRequest } from '../dashboard/calls.actions'
import { TaggingService } from '../tagging/tagging.service';
import events from '../tagging/events';
import { AdobeEventTypes } from '../tagging/tagging.types'
import { AdobeEventService } from '../tagging/adobe/event-adobe.service'
import { isRedesign } from '../../shared/utils/cookies';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AAAStore>,
    private _geocodeService: GoogleGeocodeService,
    private errorReportingService: ErrorReportingService,
    private locationService: LocationService,
    private configService: ConfigService,
    private taggingService: TaggingService,
    private adobeEventService: AdobeEventService
  ) {}

  ensureLocationServicesFunctioning = createEffect(() =>
    this.actions$.pipe(
      ofType(ENSURE_LOCATION_SERVICES),
      withLatestFrom(
        this.store$.pipe(select(selectUserDefaultCoords)),
      ),
      switchMap(([_, defaultCoords]) =>
        from(this._geocodeService.getGPSCoords(true, defaultCoords)).pipe(
          map((coordinates: GoogleCoordinates) =>
            setLocationServicesAvailable({
              payload: {available: true, coordinates},
            }
          )),
          catchError(() => of(
              setLocationServicesAvailable({
                payload: {available: Boolean(defaultCoords), coordinates: defaultCoords || null},
              })
            )
          )
        )
      )
    )
  )

  loadHomeGeoAddress = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_BREAKDOWN_LOCATION.REQUEST),
      filter(
        (action: FSA<LocationPayload, LocationMeta>) =>
          action.meta &&
          (action.meta.locationType === LOCATION_TYPE.HOME ||
            action.meta.locationType === LOCATION_TYPE.HOME_LINK)
      ),
      switchMap((action: FSA<{ member: MemberInfo }, LocationMeta>) => {
        const member: MemberInfo = action.payload.member
        const address = `${member.basicAddress}, ${member.city}, ${member.stateProvince} ${member.postalCode}`

        // NOTE: If it is possible for the service to throw a SYNCHRONOUS error, a bug will be generated.
        // The subscriber may stop listening for messages.
        return from(this._geocodeService.getLocationFromAddress(address)).pipe(
          switchMap((addresses) => {
            const aaaLocation = googleLocationToAAALocation({
              address,
              location: addresses[0],
              locationType: action.meta.locationType
            })
            return this.resolveLocation({
              aaaLocation,
            })
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifySetBreakdownLocationFailure
            )
          )
        )
      })
    )
  )

  loadCurrentPositionAddress = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_BREAKDOWN_LOCATION.REQUEST),
      filter(
        (action: ReturnType<typeof setBreakdownLocationRequest>) =>
          action.meta &&
          (action.meta.locationType === LOCATION_TYPE.PIN_DROP ||
            action.meta.locationType === LOCATION_TYPE.QUERY_PARAMS ||
            action.meta.locationType === LOCATION_TYPE.GPS_LOCATION)
      ),
      withLatestFrom(
        this.store$.select(selectHasGPSAccess),
        this.store$.pipe(select(selectUserDefaultCoords)),
      ),
      switchMap(
        ([action, hasGpsAccess, defaultCoords]: [
          ReturnType<typeof setBreakdownLocationRequest>,
          boolean,
          GoogleCoordinates
        ]) =>
          from(this.obtainCoordinates(action, hasGpsAccess, action.meta.locationType, defaultCoords)).pipe(
            withLatestFrom(
              this.store$.select(selectIsBreakdownLocationValid),
            ),
            filter(
              ([_, hasBreakdownLocationDefined]: [
                GoogleCoordinates,
                boolean
              ]) => !hasBreakdownLocationDefined || isRedesign()
            ),
            switchMap(([coords, _]: [GoogleCoordinates, boolean]) =>
              from(this._geocodeService.getLocationFromCoords(coords)).pipe(
                mergeMap((results: GoogleLocation[]) => {
                  const guess = guessLocationFromCoordsResults(results, coords)
                  const aaaLocation = googleLocationToAAALocation({
                    address: guess.formatted_address,
                    location: guess,
                    locationType: action.meta.locationType,
                    coords
                  })

                  if (action.meta.locationType === LOCATION_TYPE.PIN_DROP && !isRedesign()) {
                    this.adobeEventService.sendEvent({
                      eventName: AdobeEventTypes.CTA,
                      eventValue: events.location.LOCATION_DROP_PIN
                    })
                    this.taggingService.setClickEvent(
                      events.location.LOCATION_DROP_PIN,
                      events.location.LOCATION_PAGE_TYPE
                    )
                  }

                  return [
                    ...(isRedesign() ? [setLandMark({payload: ''})] : []),
                    ...this.resolveLocation({
                      aaaLocation,
                      googleLocation: guess,
                    })
                  ]
                })
              )
            ),
            catchError((error: Error) =>
              of(error).pipe(
                withLatestFrom(
                  this.store$.select(selectIsBreakdownLocationValid)
                ),
                switchMap(([err, isBreakdownLocationValid]) =>
                  this.errorReportingService.notifyErrorObservable(
                    err,
                    isBreakdownLocationValid
                      ? []
                      : [
                          setBreakdownLocationSuccess({ payload: null }),
                        ]
                  )
                )
              )
            )
          )
      )
    )
  )

  setBreakdownLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_BREAKDOWN_LOCATION.REQUEST),
      filter(
        (action: FSA) =>
          action.meta &&
          action.meta.locationType === LOCATION_TYPE.ADDRESS_INPUT
      ),
      switchMap((action) => {
        if (!action.payload.length) {
          return of({ type: SET_BREAKDOWN_LOCATION.SUCCESS })
        }
        return from(
          this._geocodeService.getLocationFromAddress(action.payload)
        ).pipe(
          mergeMap((addresses) => {
            const aaaLocation = googleLocationToAAALocation({
              address: action.payload,
              location: addresses[0],
              locationType: LOCATION_TYPE.ADDRESS_INPUT
            })
            if (isAddressComplete(aaaLocation) && !isHighwayAddressPattern(action.payload)) {
              return this.resolveLocation({
                googleLocation: addresses[0],
                aaaLocation,
              })
            } else {
              // if the location found by the input address isn't complete or has highway pattern,
              // try to get better results searching by the same coordinates
              if (!isAddressComplete(aaaLocation)) {
                this.taggingService.setAutomatedEvent(
                  events.location.LOCATION_INCOMPLETE_BREAKDOWN,
                  events.location.LOCATION_PAGE_TYPE
                )
              }
              if (isHighwayAddressPattern(action.payload)) {
                this.taggingService.setAutomatedEvent(
                  events.location.LOCATION_HIGHWAY_INPUT,
                  events.location.LOCATION_PAGE_TYPE
                )
              }

              const coords = {
                lng: Number(aaaLocation.longitude),
                lat: Number(aaaLocation.latitude),
              }
              return from(
                this._geocodeService.getLocationFromCoords(coords)
              ).pipe(
                switchMap((addressesByCoords) => {
                  const guess = guessLocationFromCoordsResults(
                    addressesByCoords,
                    coords
                  )
                  const guessedAaaLocation = googleLocationToAAALocation({
                    address: action.payload,
                    location: guess,
                    locationType: LOCATION_TYPE.ADDRESS_INPUT,
                    coords
                  })
                  guessedAaaLocation.guessedLocation = true
                  return [...this.resolveLocation({
                    googleLocation: guess,
                    aaaLocation: guessedAaaLocation,
                  }), setShowAdjustLocation({ payload: {
                      isAddressComplete: false,
                      address: action.payload
                    }})]
                })
              )
            }
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifySetBreakdownLocationFailure
            )
          )
        )
      })
    )
  )

  handleBreakdownLocationSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_BREAKDOWN_LOCATION.SUCCESS),
      filter(
        (action: ReturnType<typeof setBreakdownLocationSuccess>) =>
          action.payload !== null && isAddressComplete(action.payload)
      ),
      withLatestFrom(this.store$.select(selectActiveCallStatus)),
      filter(([_, activeCallStatus]) => activeCallStatus === null),
      mergeMap(
        ([action, _]: [
          ReturnType<typeof setBreakdownLocationSuccess>,
          AAACallStatus
        ]) =>
          action.payload.locationType === LOCATION_TYPE.HOME_LINK
            ? [
                locationNext(),
                addPartialCallRequest(),
                setVehicleStep({ payload: { step: 'vehicle' } }),
              ]
            : [resetAar(), resetTowDestination()]
      )
    )
  )

  getHighwayExits = createEffect(
    (): Observable<
      | ReturnType<typeof setHighwayExitsSuccess>
      | ReturnType<typeof setHighwayExitsFailure>
    > =>
      this.actions$.pipe(
        ofType(SET_HIGHWAY_EXITS.REQUEST),
        switchMap((action: PayloadedAction) =>
          from(this.locationService.getHighwayExits(action.payload)).pipe(
            withLatestFrom(this.store$.select(selectIsHighway)),
            filter(([_, isHighway]: [HighwayExits, boolean]) => isHighway),
            map(([payload]: [HighwayExits, boolean]) =>
              setHighwayExitsSuccess({ payload })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                setHighwayExitsFailure
              )
            )
          )
        ),
        catchError((error) =>
          of(dispatchErrorAction(setHighwayExitsFailure, error))
        )
      )
  )

  handleSetLocationClubRequest = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setLocationClubRequest>>(
        SET_LOCATION_CLUB.REQUEST
      ),
      switchMap(
        (action: ReturnType<typeof setLocationClubRequest>) =>
          from(this.locationService.getClubByLocation(action.payload)).pipe(
            filter((response: LocationClubResponse) =>
              Boolean(response.servicingClub && response.servicingClub !== '')
            ),
            map((response: LocationClubResponse) =>
              setLocationClubSuccess({
                payload: {
                  club: response.servicingClub,
                  country: response.country,
                  association: this.configService.getConfig().association,
                  zipcode: action.payload.zipcode
                },
              })
            ),
            catchError((error) =>
              this.errorReportingService.notifyErrorObservable(
                error,
                setLocationClubError
              )
            )
          )
      )
    )
  )

  handleSetLocationClubSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setLocationClubSuccess>>(
        SET_LOCATION_CLUB.SUCCESS
      ),
      withLatestFrom(this.store$.pipe(select(selectActiveCallStatus))),
      filter(([_, activeCallStatus]) => activeCallStatus === null),
      mergeMap(([action]) => [
        setServicingClubConfigsRequest({ payload: action.payload }),
        advisoriesRequest({
          payload: {
            association: this.configService.getConfig().association,
            club: action.payload.club,
            zipcode: action.payload.zipcode
          },
        }),
      ])
    )
  )

  handleNext = createEffect(() =>
    this.actions$.pipe(
      ofType(LOCATION_NEXT),
      switchMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.pipe(select(selectBreakdownLocation)),
            this.store$.pipe(select(selectIsHighway)),
          ),
          mergeMap(([_, location, isHighway]) => {
            const nextActions = []
            nextActions.push(
              setLocationClubRequest({
                payload: {
                  latitude: location.latitude,
                  longitude: location.longitude,
                  zipcode: location.postalCode || location.zip
                },
              })
            )
            if (isHighway) {
              nextActions.push(
                setHighwayExitsRequest({
                  payload: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                  },
                })
              )
            }

            return nextActions
          }),
          catchError((error) =>
            this.errorReportingService.notifyErrorObservable(
              error,
              notifySetBreakdownLocationFailure
            )
          )
        )
      )
    )
  )

  private async obtainCoordinates(
    action: ReturnType<typeof setBreakdownLocationRequest>,
    hasGpsAccess: boolean,
    locationType: LOCATION_TYPE,
    defaultCoords: GoogleCoordinates
  ): Promise<GoogleCoordinates> {
    const newCoords = locationType === LOCATION_TYPE.GPS_LOCATION ?
      await this._geocodeService.getGPSCoords(hasGpsAccess, defaultCoords) :
      null
    return action.payload &&
      action.payload.hasOwnProperty('lat') &&
      action.payload.hasOwnProperty('lng') &&
      (!newCoords || action.payload['lat'] === newCoords.lat || action.payload['lng'] === newCoords.lng)
        ? action.payload as GoogleCoordinates
        : newCoords as GoogleCoordinates
  }

  private resolveLocation(
    {
      aaaLocation,
      googleLocation,
    }:
    {
      aaaLocation: AAALocation,
      googleLocation?: GoogleLocation,
    }
  ) {
    const actions: Action[] = [setBreakdownLocationSuccess({ payload: aaaLocation })]

    if (googleLocation) {
      actions.push(setLocationType({ payload: googleLocation }));
      if (hasHighwayNameAndGeometricCenter( aaaLocation.address, googleLocation)) {
        actions.push(setIsHighway({ payload: true }));
      } else {
        actions.push(resetHighwayExits());
      }
    }

    return actions
  }
}
