<ion-menu contentId="aaa-menu">
  <ion-content [scrollY]="false" class="ion-padding">

    <ion-header class="ion-padding-bottom">
      <ion-row class="ion-align-items-center">
        <ion-col>
          <app-logo size="medium"></app-logo>
        </ion-col>
        <ion-col size="auto">
          <ion-menu-toggle>
            <aaa-button size="small" variant="secondary">
              <aaa-icon name="close-outline"></aaa-icon>
            </aaa-button>
          </ion-menu-toggle>
        </ion-col>
      </ion-row>
    </ion-header>


    @if (memberData$ | async; as memberData) {
      @if ((memberData?.firstName) || (eligibility$ | async)?.contractIdentityData.contact?.firstName; as firstName) {
        <aaa-text class="ion-padding-vertical" color="primary" weight="semibold" id="welcome-name" size="title2" i18n>
          Welcome, {{ firstName.split(' ')[0] | titlecase }}!
        </aaa-text>
        }
      @if (memberData?.joinDate) {
        <aaa-text class="ion-padding-top" size="subheadline" i18n>
          {{ _getMemberGreetings(memberData?.joinDate) }}
        </aaa-text>
      }
      <aaa-text class="ion-padding-bottom" size="subheadline">
        <span i18n>Membership type: </span>
        <span i18n>{{ getPlusIndicatorName(memberData.plusIndicator) }}</span>
      </aaa-text>
    } @else {
      <aaa-text class="ion-padding-vertical" color="primary" weight="semibold" id="welcome" size="title2" i18n>Welcome!</aaa-text>
    }

    <ion-list class="ion-padding-vertical">
      @if (isRapUser$ | async) {
        <ion-item button (click)="openLanguageSwitcher()">
          <aaa-icon slot="start" class="color-faint" name="globe-outline"></aaa-icon>
          <ion-label i18n>Switch Language</ion-label>
        </ion-item>
      }
      <ion-item>
        <aaa-icon slot="start" class="color-faint" name="medkit-outline"></aaa-icon>
        <ion-label i18n>Have an Emergency?</ion-label>
        <a href="tel: 911">
          <aaa-button variant="accent" size="small" slot="end">
            <aaa-icon slot="start" name="call-outline"></aaa-icon>
            <ion-label>911</ion-label>
          </aaa-button>
        </a>
      </ion-item>
    </ion-list>

    <aaa-text size="caption" color="medium" slot="fixed">
      @if (isRapUser$ | async) { <span class="powered-by" i18n>Powered by RESPONSE</span> }
      @if (appEnv && displayEnv) { {{ appEnv }} }
      @if (appVersion && displayVersion) { v{{ appVersion }} }
    </aaa-text>

  </ion-content>
</ion-menu>
