import { VehicleEditState } from './vehicle.reducer'
import { VendorConfigurationMode } from '../auth/mode-configuration.types'
import { Injectable } from '@angular/core'
import { Vehicle } from '../auth/eligibility.types'
import { VehicleDriveTypes } from './vehicle.types'
import { VehicleData } from '../member/member.types'
import { VehicleSections } from '../ui/ui.types';
import events from '../tagging/events';

export const isAWD = (vehicle: VehicleEditState | VehicleData) => vehicle.driveType === VehicleDriveTypes.ALL_WHEEL_DRIVE || vehicle.driveType === VehicleDriveTypes.FOUR_WHEEL_DRIVE
export const getModelDescription = (vehicle: VehicleEditState) => vehicle?.driveType
    ? `${vehicle.model} (${isAWD(vehicle) ? 'AWD/4WD' : vehicle.driveType})`
    : vehicle?.model

export interface Color {
  label: string,
  code: string,
  hex?: string,
}

export const COLORS = (): Color[] => [
  {
    label: $localize`silver`,
    code: 'silver',
  },
  {
    label: $localize`white`,
    code: 'white',
  },
  {
    label: $localize`black`,
    code: 'black',
  },
  {
    label: $localize`blue`,
    code: 'blue',
  },
  {
    label: $localize`red`,
    code: 'red',
  },
  {
    label: $localize`maroon`,
    code: 'maroon',
  },
  {
    label: $localize`gray`,
    code: 'gray',
  },
  {
    label: $localize`purple`,
    code: 'purple',
  },
  {
    label: $localize`brown`,
    code: 'brown',
  },
  {
    label: $localize`gold`,
    code: 'gold',
  },
  {
    label: $localize`green`,
    code: 'green',
  },
  {
    label: $localize`yellow`,
    code: 'yellow',
  },
  {
    label: $localize`orange`,
    code: 'orange',
  },
]
export const PAGE_NAME_MAPPING = {
  [VehicleSections.MAKES]: events.vehicle.PAGE_NAME_SELECT_MAKE,
  [VehicleSections.MODELS]: events.vehicle.PAGE_NAME_SELECT_MODEL,
  [VehicleSections.DETAILS]: events.vehicle.VEHICLE_SELECT_COLOR,
  [VehicleSections.YEARS]: events.vehicle.PAGE_NAME_SELECT_YEAR,
  [VehicleSections.PLATE]: events.vehicle.PAGE_NAME_VEHICLE_PLATE,
  [VehicleSections.CONFIRM]: events.vehicle.PAGE_NAME_VEHICLE_CONFIRM,
}

export const FUEL_TYPE_ELECTRIC = 'ELECTRIC'

@Injectable({
  providedIn: 'root',
})
export class VehicleUtils {

  isRestrictModel = (rapConfig: VendorConfigurationMode, vehicle: Vehicle) => {
    if(!rapConfig.restrictModels) {
      return false
    }
    const restrictedMake = Object.keys(rapConfig.restrictModels).find(make => make.toLowerCase() === vehicle.make.toLowerCase())
    const model = rapConfig.restrictModels[restrictedMake]?.find(_model => vehicle.model.toLowerCase().indexOf(_model.toLowerCase()) > -1)
    return model !== undefined
  }

  normalizeMotorHome = (str: string | string[]) => {
    if (typeof str === 'string') {
      return str.replace(/\//g, '-');
    } else {
      return str.map(s => s.replace(/\//g, '-'));
    }
  }

  uriEncode = (str: string | string[]) => {
    if (typeof str === 'string') {
      return encodeURIComponent(str);
    } else {
      return str.map(s => encodeURIComponent(s));
    }
  }

  isColorAvailable = (color: string) => {
    if (!color) {
      return false
    }
    return !!this.getColor(color)
  }

  getColor = (color: string): Color =>
    COLORS().find(c => c.code.toLowerCase() === color?.toLowerCase()
      || c.label.toLowerCase() === color?.toLowerCase())
}
