import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core'
import { VehicleColor } from '../vehicle.types'
import { findColorByName, OTHER_COLOR } from '../vehicle.contants'

@Component({
  selector: 'aaa-vehicle-color-circle',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './vehicle-color-circle.component.html',
  styleUrl: './vehicle-color-circle.component.scss',
  imports: [],
})
export class VehicleColorCircleComponent implements OnInit {
  @Input() color: VehicleColor
  @Input() colorString: string
  colorHex = OTHER_COLOR.hex
  shadeColor = this.getShadeColor(OTHER_COLOR.shade, OTHER_COLOR.shadeAlpha)

  ngOnInit() {
    if (this.color) {
      const color = this.color
      if (color) {
        this.colorHex = color.hex
        this.shadeColor = this.getShadeColor(color.shade, color.shadeAlpha)
      }
    }
    if (this.colorString) {
      const colorString = this.colorString
      if (colorString) {
        const color = findColorByName(colorString)

        if (color) {
          this.colorHex = color.hex
          this.shadeColor = this.getShadeColor(color.shade, color.shadeAlpha)
        }
      }
    }
  }

  getShadeColor(hex: string, alpha: number) {
    const rgba = this.hexToRgba(hex, alpha);
    const rgbaString = `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
    return rgbaString;
  }

  private hexToRgba(hex: string, alpha = 1): { r: number; g: number; b: number; a: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: alpha,
        }
      : {
          r: 0,
          g: 0,
          b: 0,
          a: alpha,
        };
  }
}
